import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  // insertCss: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  country: PropTypes.string,
  intl: IntlProvider.childContextTypes.intl,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  getUrlByLocale: PropTypes.func.isRequired,
  displayPriceWithCurrency: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  apiServerUrl: PropTypes.string.isRequired,
  getLocaleLink: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
  env: PropTypes.string,
  user: PropTypes.object,
  filterSearch: PropTypes.shape({
    schools: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    courses: PropTypes.array.isRequired,
    lessons: PropTypes.array.isRequired,
    accommodations: PropTypes.array.isRequired,
    course: PropTypes.string,
    lesson: PropTypes.string,
    accommodation: PropTypes.string,
    accommodationOffer: PropTypes.string,
    noAccommodation: PropTypes.bool,
    courseStartDate: PropTypes.string,
    courseEndDate: PropTypes.string,
    accommodationCheckIn: PropTypes.string,
    accommodationCheckOut: PropTypes.string,
    duration: PropTypes.number.isRequired,
    age: PropTypes.number.isRequired,
    notMandatoryOptions: PropTypes.array.isRequired,
  }),
  countries: PropTypes.array,
  cities: PropTypes.array,
  currencies: PropTypes.array,
  schools: PropTypes.array,
  languages: PropTypes.array,
  courseCategories: PropTypes.array,
  lessonsPerWeek: PropTypes.array,
  accommodationCategories: PropTypes.array,
  accommodationMealCategories: PropTypes.array,
  accommodationRoomCategories: PropTypes.array,
  magazineCategories: PropTypes.array,
  faq: PropTypes.object,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */

class App extends React.PureComponent {
  getChildContext() {
    return this.props.context;
  }

  componentDidMount() {
    const { context } = this.props;
    // snapchat tag
    /*
    if (context.env && context.env === 'production') {
      (function(win, doc, sdk_url){
        if(win.snaptr) return;
        var tr=win.snaptr=function(){
        tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
      };
        tr.queue = [];
        var s='script';
        var new_script_section=doc.createElement(s);
        new_script_section.async=!0;
        new_script_section.src=sdk_url;
        var insert_pos=doc.getElementsByTagName(s)[0];
        insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
      })(window, document, 'https://sc-static.net/scevent.min.js');

      if (window.snaptr) {
        window.snaptr('init', 'c7294fda-9483-4b68-93d9-ebbe4275f31b', {
          'user_email': context && context.user ? context.user.email : ''
        });
        window.snaptr('track', 'PAGE_VIEW');
      }
    }
    */
    // crisp chat
    /*if (context.env && context.env === 'production'
     && context.pathname !== '/fr/liste-verbe-irregulier-anglais'
     && context.pathname !== '/en/list-of-irregular-verbs-english') {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '543274fb-954b-45f6-959b-537085ab2f09';
      if (context.locale) {
        window.CRISP_RUNTIME_CONFIG = {
          locale: context.locale,
        };
      }
      (function() {
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
      window.$crisp.push(['safe', true]);
      if (context.user && context.user.email) {
        window.$crisp.push(['set', 'user:email', context.user.email]);
        if (context.user.firstName) {
          window.$crisp.push(['set', 'user:nickname', `${context.user.firstName} ${context.user.lastName}`]);
        }
        if (context.user.pictureUrl) {
          window.$crisp.push(['set', 'user:avatar', context.user.pictureUrl]);
        }
      }
    }*/
    if (context?.user?.isNew) {
      /*const affliaeUrl = `https://lb.affilae.com/?key=5eb17ac11f8fb8315cb0ee69-5eb176721f8fb8315cb0ee5d&id=${context.user.id}&CUSTOMER_ID=${context.user.id}`;
      const iframe = document.createElement('iframe');
      iframe.src = affliaeUrl;
      iframe.width = 1;
      iframe.height = 1;
      iframe.frameBorder = 0;
      iframe.style = 'display:none';
      document.body.appendChild(iframe);*/
    }
    if (context?.user?.isNew) {
      /*if (window?.gtag) {
        window.gtag('event', 'conversion', { send_to: 'AW-740087358/FjJ6CLzJp9IBEL6s8-AC' });
      } */
    }
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    return React.Children.only(this.props.children);
  }
}

App.propTypes = {
  context: PropTypes.shape(ContextType).isRequired,
  children: PropTypes.element.isRequired,
};

App.childContextTypes = ContextType;

export default App;
