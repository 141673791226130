export const displayPriceWithCurrency =
(price, schoolCurrency, userCurrency, currencies, locale) => {
  const schoolCurrencyInfo = currencies?.find((tmpCurrency) => tmpCurrency.code === schoolCurrency);
  const userCurrencyInfo = currencies?.find((tmpCurrency) => tmpCurrency.code === userCurrency);
  if (!schoolCurrencyInfo || !userCurrencyInfo) {
    return '--';
  }
  let finalPrice = 0;
  if (schoolCurrency !== 'EUR' && userCurrency === 'EUR') {
    // put price in euro
    finalPrice = Math.round(price / schoolCurrencyInfo.exchangeRateWithEuro);
  } else if (schoolCurrency === 'EUR' && userCurrency !== 'EUR') {
    finalPrice = Math.round(price * (userCurrencyInfo.exchangeRateWithEuro));
  } else if (schoolCurrency === userCurrency) {
    finalPrice = price;
  } else if (schoolCurrency !== 'EUR' && userCurrency !== 'EUR') {
    // put price in euro
    finalPrice = Math.round(price / schoolCurrencyInfo.exchangeRateWithEuro);
    // put price to userCurrency form
    finalPrice *= userCurrencyInfo.exchangeRateWithEuro;
  }
  if (finalPrice > 0) {
    finalPrice = Math.round(finalPrice * 100) / 100;
    return new Intl.NumberFormat(locale, { maximumSignificantDigits: 4, style: 'currency', currency: userCurrency }).format(finalPrice);
  }
  return locale === 'fr' ? 'Offert' : 'Free';
};

export default displayPriceWithCurrency;
