const linkObject = {
  'homepage': {
    en: 'en',
    fr: 'fr',
    es: 'es',
    isIndexSeo: true,
  },
  'why-book-with-us': {
    en: 'en/pages/why-book-with-us',
    fr: 'fr/pages/pourquoi-reserver-avec-nous',
    es: 'es/pages/por-que-reservar-con-nosotras',
    isIndexSeo: true,
  },
  'privacy-policy': {
    en: 'en/pages/privacy-policy',
    fr: 'fr/pages/privacy-policy',
    es: 'es/pages/privacy-policy',
    isIndexSeo: false,
  },
  'terms-of-use': {
    en: 'en/pages/terms-of-use',
    fr: 'fr/pages/conditions-d-utilisation',
    es: 'es/pages/terms-of-use',
    isIndexSeo: false,
  },
  'cookie-policy': {
    en: 'en/pages/cookie-policy',
    fr: 'fr/pages/cookie-policy',
    es: 'es/pages/cookie-policy',
    isIndexSeo: false,
  },
  'let-us-book-your-language-school': {
    en: 'en/pages/let-us-book-your-language-school',
    fr: 'fr/pages/laissez-nous-reserver-votre-sejour-linguistique',
    es: 'es/pages/dejanos-reservar-tu-escuela-de-idiomas',
    isIndexSeo: true,
  },
  'request-call-back': {
    en: 'en/request-a-callback',
    fr: 'fr/request-a-callback',
    es: 'es/request-a-callback',
    isIndexSeo: false,
  },
  dashboard: {
    en: 'en/account/dashboard',
    fr: 'fr/account/dashboard',
    es: 'es/account/dashboard',
    isIndexSeo: false,
  },
  profile: {
    en: 'en/account/profile',
    fr: 'fr/account/profile',
    es: 'es/account/profile',
    isIndexSeo: false,
  },
  booking: {
    en: 'en/account/booking',
    fr: 'fr/account/booking',
    es: 'es/account/booking',
    isIndexSeo: false,
  },
  wishlist: {
    en: 'en/account/wishlist',
    fr: 'fr/account/wishlist',
    es: 'es/account/wishlist',
    isIndexSeo: false,
  },
  settings: {
    en: 'en/account/settings',
    fr: 'fr/account/settings',
    es: 'es/account/settings',
    isIndexSeo: false,
  },
  review: {
    en: 'en/account/review',
    fr: 'fr/account/review',
    es: 'es/account/review',
    isIndexSeo: false,
  },
  school: {
    en: 'en/school',
    fr: 'fr/ecole',
    es: 'es/escuela',
    isIndexSeo: false,
  },
  schoolOnline: {
    en: 'en/school-online',
    fr: 'fr/ecole-en-ligne',
    es: 'es/escuela-virtual',
    isIndexSeo: false,
  },
  login: {
    en: 'en/login',
    fr: 'fr/login',
    es: 'es/login',
    isIndexSeo: false,
  },
  register: {
    en: 'en/register',
    fr: 'fr/register',
    es: 'es/register',
    isIndexSeo: false,
  },
  magazine: {
    en: 'en/magazine',
    fr: 'fr/magazine',
    es: 'es/magazine',
    isIndexSeo: true,
  },
  magazineCategory: {
    en: 'en/magazine/category',
    fr: 'fr/magazine/category',
    es: 'es/magazine/category',
    isIndexSeo: true,
  },
  partner: {
    en: 'en/partner-backoffice',
    fr: 'fr/partner-backoffice',
    es: 'es/partner-backoffice',
    isIndexSeo: false,
  },
  contact: {
    en: 'en/contact',
    fr: 'fr/contact',
    es: 'es/contact',
    isIndexSeo: false,
  },
  destination: {
    en: 'en/destination',
    fr: 'fr/destination',
    es: 'es/destino',
    isIndexSeo: true,
  },
  search: {
    en: 'en/language-stay',
    fr: 'fr/sejour-linguistique',
    es: 'es/estancias-linguisticas',
    isIndexSeo: true,
  },
  searchCourseCategory: {
    en: 'en/language-course',
    fr: 'fr/cours-de-langue',
    es: 'es/curso-de-idioma',
    isIndexSeo: false,
  },
  searchSchoolCountry: {
    en: 'en/best-language-schools',
    fr: 'fr/meilleurs-ecoles-de-langue',
    es: 'es/mejores-escuelas-de-idiomas',
    isIndexSeo: false,
  },
  faq: {
    en: 'en/faq',
    fr: 'fr/faq',
    es: 'es/preguntas-frecuentes',
    isIndexSeo: true,
  },
  deals: {
    en: 'en/language-stay-deal',
    fr: 'fr/sejour-linguistique-promotion',
    es: 'es/promocion-estancia-idioma',
    isIndexSeo: true,
  },
  irregularVerbsEnglish: {
    en: 'en/list-of-irregular-verbs-english',
    fr: 'fr/liste-verbe-irregulier-anglais',
    es: 'es/lista-de-verbos-irregulares-ingles',
    isIndexSeo: true,
  },
  covid: {
    en: 'en/magazine/health-situation-destinations-covid',
    fr: 'fr/magazine/covid-situation-par-destination',
    es: 'es/magazine/situacion-sanitaria-destinos-covid',
    isIndexSeo: true,
  },
};

export function getLocaleLink(routeName, locale) {
  // console.log('routeName', routeName);
  // console.log('locale', locale);
  return linkObject[routeName][locale];
}


export function getAllLocaleLink() {
  // console.log('routeName', routeName);
  // console.log('locale', locale);
  return linkObject;
}
