// The top-level (parent) route

const routes = [
  {
    path: '',
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'homepage' */ './home'),
      },
      {
        path: '/:locale',
        // Keep in mind, routes are evaluated in order
        children: [
          {
            path: '',
            load: () => import(/* webpackChunkName: 'homepage' */ './home'),
          },
          {
            path: '/pages/:pageSlug',
            load: () => import(/* webpackChunkName: 'pages' */ './pages'),
          },
          {
            path: '/destination',
            load: () => import(/* webpackChunkName: 'destination' */ './country'),
          },
          {
            path: '/destino',
            load: () => import(/* webpackChunkName: 'destination' */ './country'),
          },
          {
            path: '/destination/:country',
            load: () => import(/* webpackChunkName: 'destination' */ './country'),
          },
          {
            path: '/destino/:country',
            load: () => import(/* webpackChunkName: 'destination' */ './country'),
          },
          {
            path: '/language-stay',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/language-stay/:country',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/language-stay/:country/:city',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/sejour-linguistique',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/estancias-linguisticas',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/cours-de-langue',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/language-course',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/curso-de-idioma',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/cours-de-langue/:category',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/language-course/:category',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/curso-de-idioma/:category',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/meilleurs-ecoles-de-langue/:schoolsCountry',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/mejores-escuelas-de-idiomas/:schoolsCountry',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/best-language-schools/:schoolsCountry',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/sejour-linguistique/:country',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/sejour-linguistique/:country/:city',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/estancias-linguisticas/:country',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/estancias-linguisticas/:country/:city',
            load: () => import(/* webpackChunkName: 'search' */ './search'),
          },
          {
            path: '/school/:schoolSlug',
            load: () => import(/* webpackChunkName: 'school' */ './school'),
          },
          {
            path: '/ecole/:schoolSlug',
            load: () => import(/* webpackChunkName: 'school' */ './school'),
          },
          {
            path: '/escuela/:schoolSlug',
            load: () => import(/* webpackChunkName: 'school' */ './school'),
          },
          {
            path: '/school-online/:schoolSlug',
            load: () => import(/* webpackChunkName: 'school-online' */ './school-online'),
          },
          {
            path: '/ecole-en-ligne/:schoolSlug',
            load: () => import(/* webpackChunkName: 'school-online' */ './school-online'),
          },
          {
            path: '/escuela-virtual/:schoolSlug',
            load: () => import(/* webpackChunkName: 'school-online' */ './school-online'),
          },
          {
            path: '/login',
            load: () => import(/* webpackChunkName: 'login' */ './login'),
          },
          {
            path: '/register',
            load: () => import(/* webpackChunkName: 'register' */ './register'),
          },
          {
            path: '/reset-password',
            load: () => import(/* webpackChunkName: 'reset-password' */ './reset-password'),
          },
          {
            path: '/reset-new-password',
            load: () => import(/* webpackChunkName: 'reset-new-password' */ './reset-new-password'),
          },
          {
            path: '/magazine',
            load: () => import(/* webpackChunkName: 'magazine' */ './magazine'),
          },
          {
            path: '/magazine/category/:categorySlug',
            load: () => import(/* webpackChunkName: 'magazine-category' */ './magazine-category'),
          },
          {
            path: '/magazine/:articleSlug',
            load: () => import(/* webpackChunkName: 'magazine-article' */ './magazine-article'),
          },
          {
            path: '/account/dashboard',
            load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
          },
          {
            path: '/account/profile',
            load: () => import(/* webpackChunkName: 'profile' */ './profile'),
          },
          {
            path: '/account/review',
            load: () => import(/* webpackChunkName: 'review' */ './review'),
          },
          {
            path: '/account/settings',
            load: () => import(/* webpackChunkName: 'profile-settings' */ './profile-settings'),
          },
          {
            path: '/account/wishlist',
            load: () => import(/* webpackChunkName: 'wishlist' */ './wishlist'),
          },
          {
            path: '/account/booking',
            load: () => import(/* webpackChunkName: 'booking-history' */ './booking-history'),
          },
          {
            path: '/checkout/:bookingId',
            load: () => import(/* webpackChunkName: 'checkout' */ './checkout'),
          },
          {
            path: '/checkout-confirmation/:bookingId',
            load: () => import(/* webpackChunkName: 'checkout-confirmation' */ './checkout-confirmation'),
          },
          {
            path: '/contact',
            load: () => import(/* webpackChunkName: 'contact' */ './contact'),
          },
          {
            path: '/faq',
            load: () => import(/* webpackChunkName: 'faq' */ './faq'),
          },
          {
            path: '/preguntas-frecuentes',
            load: () => import(/* webpackChunkName: 'faq' */ './faq'),
          },
          {
            path: '/request-a-callback',
            load: () => import(/* webpackChunkName: 'callback' */ './callback'),
          },
          {
            path: '/liste-verbe-irregulier-anglais',
            load: () => import(/* webpackChunkName: 'verbs' */ './tool-verbs'),
          },
          {
            path: '/lista-de-verbos-irregulares-ingles',
            load: () => import(/* webpackChunkName: 'verbs' */ './tool-verbs'),
          },
          {
            path: '/list-of-irregular-verbs-english',
            load: () => import(/* webpackChunkName: 'verbs' */ './tool-verbs'),
          },
          {
            path: '/language-stay-deal',
            load: () => import(/* webpackChunkName: 'last-minute-deals' */ './last-minute-deals'),
          },
          {
            path: '/sejour-linguistique-promotion',
            load: () => import(/* webpackChunkName: 'last-minute-deals' */ './last-minute-deals'),
          },
          {
            path: '/promocion-estancia-idioma',
            load: () => import(/* webpackChunkName: 'last-minute-deals' */ './last-minute-deals'),
          },
          {
            path: '/language-stay-deal/:country',
            load: () => import(/* webpackChunkName: 'last-minute-deals' */ './last-minute-deals'),
          },
          {
            path: '/sejour-linguistique-promotion/:country',
            load: () => import(/* webpackChunkName: 'last-minute-deals' */ './last-minute-deals'),
          },
          {
            path: '/promocion-estancia-idioma/:country',
            load: () => import(/* webpackChunkName: 'last-minute-deals' */ './last-minute-deals'),
          },
          {
            path: '/wireframes/:dev',
            load: () => import(/* webpackChunkName: 'wireframes' */ './wireframes'),
          },
          {
            path: '/upload-image',
            load: () => import(/* webpackChunkName: 'upload-image' */ './upload-image'),
          },
          // {
          // path: '/inbox',
          // load: () => import(/* webpackChunkName: 'message' */ './inbox'),
          // },
          // {
          //  path: '/message/:conversationId',
          //  load: () => import(/* webpackChunkName: 'message' */ './message'),
          // },
          // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
          {
            path: '(.*)',
            load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
          },
        ],
        
        async action({ next, params }) {
          const currentLocales = ['fr', 'en', 'es'];
          if (params.locale && !currentLocales.includes(params.locale)) {
            return { redirect: '/en/404', status: 404 };
          }
          // Execute each child route until one of them return the result
          const route = await next();
          // Provide default values for title, description etc.
          route.title = `${route.title || 'Untitled Page'}`;
          route.description = route.description || '';
          route.locale = params.locale;
          route.isHome = false;
          return route;
        },
      },
      
    ],
    async action({ next }) {
      // Home page EN (Without params)
      const route = await next();
      route.title = `${route.title || 'Untitled Page'}`;
      route.description = route.description || '';
      route.isHome = true;
      return route;
    },
  },
];

// The error page is available by permanent url for development mode

/*
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}
*/
export default routes;
