import { IntlProvider } from 'react-intl';

export function getIntlFromStateServer(localeData, locale) {
//   const intl = (state && state.intl) || {};
  // let { initialNow, locale, messages } = intl;
  // const localeMessages = (messages && messages[locale]) || {};
  const messages = localeData.reduce((msgs, msg) => {
    msgs[msg.id] = msg.message; // eslint-disable-line no-param-reassign
    return msgs;
  }, {});
  const provider = new IntlProvider({
    locale,
    messages: locale === 'en' ? {} : messages,
    defaultLocale: locale,
  });
  return provider.getChildContext().intl;
}

export function getIntlFromStateClient(intl, locale) {
  const { messages } = intl;
  const provider = new IntlProvider({
    locale,
    messages,
    defaultLocale: locale,
  });
  return provider.getChildContext().intl;
}

export function getUrlByLocale(s) {
  if (typeof s !== 'string') return '';
  if (s === 'en') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}
